/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

/**custom badges*/
.approve-badge {
	background: rgba(40, 199, 111, 0.12) !important;
	border-radius: 17px;
	padding: 4px 16px;
	justify-content: center !important;
	align-items: center !important;
	color: #28c76f !important;
}

.suspended-badge {
	background: rgba(108, 117, 125, 0.12) !important;
	border-radius: 17px;
	padding: 4px 16px;
	color: #82868b;
	justify-content: center !important;
	align-items: center !important;
}

.pending-badge {
	background: rgba(255, 159, 67, 0.12) !important;
	border-radius: 17px;
	padding: 4px 16px;
	color: #ff9f43;
	justify-content: center !important;
	align-items: center !important;
}

.saved-badge {
	background: rgba(97, 177, 218, 0.12) !important;
	border-radius: 17px;
	padding: 4px 16px;
	color: #60b8e4;
	justify-content: center !important;
	align-items: center !important;
}

.confirmed_badge {
	background: rgba(71, 173, 222, 0.1) !important;
	border-radius: 17px;
	padding: 4px 16px;
	color: #42b0ee;
	justify-content: center !important;
	align-items: center !important;
}

.un-confirmed-badge {
	background: #42b0ee !important;
	border-radius: 17px;
	padding: 4px 16px;
	color: #82868b;
	justify-content: center !important;
	align-items: center !important;
}

.att-mng-excel-download {
	font-size: 10px;
	padding-left: 9px;
	padding-right: 9px;
}

.confirmed_badge {
	background: rgba(71, 173, 222, 0.1) !important;
	border-radius: 17px;
	padding: 4px 16px;
	color: #42b0ee;
	justify-content: center !important;
	align-items: center !important;
}

.unapproved_badge {
	background: #42b0ee !important;
	border-radius: 17px;
	padding: 10px 16px;
	color: #fff;
	justify-content: center !important;
	align-items: center !important;
}

.rdt_Table {
	min-height: 290px;
}

.graph-dropdown {
	background: #ffffff;
	border: 0.8px solid #c0c0c7 !important;
	box-sizing: border-box;
	box-shadow: 0px 4px 15px rgba(116, 125, 136, 0.1);
	border-radius: 7px;
	color: #4b4948 !important;
	max-height: 200px;
	overflow: auto;
}

.punch-link {
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 18px;
	text-decoration-line: underline;
	color: #55656c;
	&:hover {
		color: #55656c;
		text-decoration-line: underline;
	}
}

.punch-link2 {
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 18px;
	// text-decoration-line: underline;
	color: #55656c;
	// &:hover {
	// 	color: #55656c;
	// 	text-decoration-line: underline;
	// }
}

.punch-link3 {
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 18px;
	// text-decoration-line: underline;
	color: #f12214;
	// &:hover {
	// 	color: #55656c;
	// 	text-decoration-line: underline;
	// }
}

.punch-map {
	color: #55656c;
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 23px;
	display: flex;
	align-items: center;
	letter-spacing: 0.4px;
}

.box-attendance {
	background: rgba(238, 125, 66, 0.05);
	border-radius: 6px;
	width: 38px;
	height: 38px;
	justify-content: center;
	align-items: center;
	display: flex;
}

.box-attendance-text {
	color: #4b4948;
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 21px;
}

.un-approved-badge {
	background:rgba(108, 117, 125, 0.12) !important;
	border-radius: 17px;
	padding: 4px 16px;
	color: #82868B;
	justify-content: center !important;
	align-items: center !important;
}

.apexcharts-tooltip-title {
	font-family: 'Noto Sans JP';
	background: #fff !important;
	color: #55656C;
	font-size: 10px;
	line-height: 14px;
}

.apexcharts-tooltip {
    background: #f3f3f3;
    color: #B5B5B5;
  }